

// export const paymentDetails = {
//     gpay:"7609095444@okbizaxis",
//     paytm:"7609095444@okbizaxis",
//     phonepe:"ccpay.6528680495134008@icici",
//     upi:"ccpay.6528680495134008@icici"
// }

// export const paymentDetails = {
//     gpay:"7609095444@okbizaxis",
//     paytm:"7609095444@okbizaxis",
//     phonepe:"ccpay.4035625478314003@icici",
//     upi:"ccpay.4035625478314003@icici"
// }



export const paymentDetails = {
    gpay:"paytm.s18qess@pty",
    paytm:"paytm.s18qess@pty",
    phonepe:"paytm.s18qess@pty",
    upi:"paytm.s18qess@pty"
}


// export const paymentDetails = {
//     gpay:"ombk.AAEP074961dpa8ev8wjo@mbk",
//     paytm:"ombk.AAEP074961dpa8ev8wjo@mbk",
//     phonepe:"ombk.AAEP074961dpa8ev8wjo@mbk",
//     upi:"ombk.AAEP074961dpa8ev8wjo@mbk"
// }